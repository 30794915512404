import { ThemeProvider } from '@audi/audi-ui-react';
import React, { useState, useEffect, useContext } from 'react';
import { transformToTrackingText, useContent, useInViewEffect } from '@oneaudi/feature-app-utils';
import { ConsumptionsAndEmissions } from '@oneaudi/vue-formatter-service';
import { UniversalEditorProvider } from '@oneaudi/falcon-tools';
import * as pkg from '../../package.json';
import { ParallaxTeaserComponent } from './components/ParallaxTeaser';
import { AsyncFeatureAppProps, ParallaxTeaserFeatureAppProps } from './FeatureAppTypes';
import { useTrackingManager } from './context/useTrackingManager';
import { Context, useLogger } from './context';
import { Content, HeadlessContent } from './EditorContentTypes';
import { mapHeadlessContent } from './utils/mapHeadlessContent';
import { createInitialState, enhanceInitialState } from './utils/stateUtils';

const ParallaxTeaserFeatureApp = ({ consumptionsAndEmissions }: ParallaxTeaserFeatureAppProps) => {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const initialContent = useContent<Content | HeadlessContent | any>();
  const { contentService, renderModeService } = useContext(Context);
  const renderMode = renderModeService?.getRenderMode?.() ?? 0;

  const content = mapHeadlessContent(initialContent, renderMode);
  const teaserProps = enhanceInitialState(consumptionsAndEmissions, content);

  const trackingManager = useTrackingManager();

  const linkTracking = (href: string, text: string, clickId: string): void => {
    trackingManager.click(transformToTrackingText(content.headline), href, text, clickId);
  };

  useEffect(() => {
    if (renderMode === 0) trackingManager.ready(pkg.version);
  }, []);

  useInViewEffect(() => {
    if (renderMode === 0) trackingManager.impression(transformToTrackingText(content.headline));
  });

  return renderModeService && renderMode === 1 ? (
    <UniversalEditorProvider contentService={contentService} renderModeService={renderModeService}>
      <ThemeProvider>
        <ParallaxTeaserComponent {...teaserProps} />
      </ThemeProvider>
    </UniversalEditorProvider>
  ) : (
    <ThemeProvider>
      <ParallaxTeaserComponent {...teaserProps} linkTracking={linkTracking} />
    </ThemeProvider>
  );
};
ParallaxTeaserFeatureApp.displayName = 'ParallaxTeaserFeatureApp';

const FeatureApp = ({ asyncStateHolder }: AsyncFeatureAppProps) => {
  // when asyncStateHolder is an object it represents the serialized state coming
  // from the server ready to be used as the initial state
  const [state, setState] = useState<ConsumptionsAndEmissions[] | undefined>(
    typeof asyncStateHolder === 'object' ? asyncStateHolder : undefined,
  );

  const { vueFormatterService, localeService, renderModeService } = useContext(Context);

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const initialContent = useContent<Content | HeadlessContent | any>();
  const content = mapHeadlessContent(initialContent, renderModeService?.getRenderMode?.() || 0);

  const logger = useLogger();

  React.useEffect(() => {
    let mounted = true;
    if (state && content) {
      createInitialState(content, vueFormatterService, localeService, logger).then((newState) => {
        if (mounted) {
          setState(newState);
        }
      });
    }

    return () => {
      mounted = false;
    };
  }, [initialContent]);

  React.useEffect(() => {
    // when asyncStateHolder is a function it means the state could not be properly serialized
    // by the server and it isnot available on the client. In that case this effect will try
    // to fetch the state as soon as the component is mounted on the client.
    if (typeof asyncStateHolder === 'function') {
      logger?.info('SSR did not serialize any state');
      asyncStateHolder().then(setState);
    } else {
      logger?.info('SSR serialized state: ', asyncStateHolder);
    }
  }, []);

  if (!state) {
    return null;
  }

  return <ParallaxTeaserFeatureApp consumptionsAndEmissions={state} />;
};

export default FeatureApp;
